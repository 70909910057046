import axios from 'axios';

import {
  replaceParams,
  isDev,
  sleep,
  getBaseApiUrl,
  refreshToken
} from './helpers';
import { AppThunk, AppDispatch } from 'core/store';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import store from 'core/store';
import { displayError } from 'core/services/AlertActions';
import { logout } from '../../features/shared/login/LoginSlice';
import { TWO_SECONDS } from 'core/constants';
import { setLoading } from '../services/LoadingSlice';
import { setSignupLoading } from 'features/shared/signup/SignupSlice';

interface ApiUrlOptions {
  route: string;
  replace?: Array<any>;
}

interface AsyncActionOptions {
  // Required Params:
  route: string;

  // Optional Params:
  method?: 'get' | 'post' | 'put' | 'patch' | 'delete';
  replace?: Array<any>;
  body?: Object;
  params?: Object;
  defaultErrorMessage?: string;
  onStart?: ActionCreatorWithPayload<any, any> | Function | [Function, any];
  onSuccess?: ActionCreatorWithPayload<any, any> | Function | [Function, any];
  onError?: ActionCreatorWithPayload<any, any> | Function | [Function, any];
  onComplete?: Function;
  useGlobalLoader?: boolean;
  onGlobalSuccess?:
    | ActionCreatorWithPayload<any, any>
    | Function
    | [Function, any];
  paginate?: boolean;
}

const getToken = () => {
  const { login } = store.getState();
  return login.accessToken;
};

export const constructApiUrl = (options: ApiUrlOptions): string => {
  const { route, replace } = options;
  const apiBaseUrl = getBaseApiUrl();

  if (isDev() && route[0] !== '/') {
    console.warn("Routes must start with '/'");
  }

  let url = apiBaseUrl;
  url += replace && replace.length ? replaceParams(route, replace) : route;

  return String(url);
};

export const fetchAllPages = async ({
  url,
  params,
  method = 'get',
  body,
  token
}: {
  url: string;
  params?: any;
  method?: 'get' | 'post' | 'put' | 'patch' | 'delete';
  body?: any;
  token?: string | null;
}): Promise<any[]> => {
  let allResults: any[] = [];
  let currentPage = 1;
  let hasMorePages = true;

  while (hasMorePages) {
    const requestParams = { ...params, page: currentPage };
    const response = await axios({
      method,
      params: requestParams,
      url,
      headers: {
        Authorization: token ? `Bearer ${token}` : null
      },
      data: body
    });
    const results = response.data.results || response.data;
    allResults = allResults.concat(results);
    hasMorePages = !!response.data.results.has_next;
    currentPage += 1;
  }

  const combinedItemsResult = allResults.reduce((accumulator, currentValue) => {
    accumulator.items = accumulator.items.concat(currentValue.items);
    return accumulator;
  });

  return combinedItemsResult;
};

export const createApiRequest = (options: AsyncActionOptions): Function => {
  const {
    route,
    replace,
    body,
    params,
    method,
    onStart,
    onSuccess,
    onComplete,
    onError,
    useGlobalLoader,
    onGlobalSuccess,
    defaultErrorMessage,
    paginate
  } = options;
  return (): AppThunk => async (dispatch: AppDispatch) => {
    const url = constructApiUrl({ route, replace });
    if (typeof onStart === 'function') {
      dispatch(onStart(true));
    }
    if (useGlobalLoader) {
      dispatch(setLoading(true));
      dispatch(setSignupLoading(true));
    }
    if (Array.isArray(onStart) && typeof onStart[0] === 'function') {
      const params = onStart.slice(1);
      dispatch(onStart[0](...params));
    }
    try {
      const token = getToken();
      let results;

      if (paginate) {
        results = await fetchAllPages({
          url,
          params,
          method,
          body,
          token
        });
      } else {
        const request = await axios({
          method: method || 'get',
          params: params,
          url,
          headers: {
            Authorization: token ? `Bearer ${token}` : null
          },
          data: body
        });
        results = request.data.results || request.data;
        console.log(results);
        if (typeof onGlobalSuccess === 'function') {
          if (request.data.globals) {
            dispatch(onGlobalSuccess(request.data.globals));
          }
        }
      }

      if (typeof onSuccess === 'function') {
        dispatch(onSuccess(results));
      }
      if (typeof onComplete === 'function') {
        dispatch(onComplete());
      }
      if (useGlobalLoader) {
        dispatch(setLoading(false));
        dispatch(setSignupLoading(false));
        // await sleep(HALF_SECOND); //NOTE: this waits for our loading animation to finish
      }
    } catch (e: any) {
      if (useGlobalLoader) {
        dispatch(setLoading(false));
        dispatch(setSignupLoading(false));
      }

      // clear the long-life token
      if (!!e.response && e.response.status === 422) {
        await sleep(TWO_SECONDS);
        dispatch(logout());
      } else if (
        !!e.response &&
        e.response.status === 401 &&
        e.response.error_description !== 'User Auth Failure'
      ) {
        refreshToken();
      } else {
        console.log(e);
        if (!!e.response?.data) {
          dispatch(displayError(e.response.data.error_description));
        } else {
          dispatch(displayError(!!e.message ? e.message : defaultErrorMessage));
        }
        if (typeof onError === 'function')
          dispatch(onError(defaultErrorMessage));
      }

      if (isDev()) {
        console.error(
          `Homebook API Message:\n URL: ${url}\n Error: ${e.message}`
        );
      }
    }
  };
};
